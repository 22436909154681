<template >
    <div class="columns container is-fluid">
        <!-- <div class="column is-2">
          <router-link class="is-size-4 has-text-weight-bold has-text-black" to="/global/all-companies">Все предприятия</router-link>
          <ul class="ml-2" v-for="item in GetAllCompanies" :key="item.id">
            <li class="has-text-weight-semibold has-text-black">{{item.factory.branch.name}}</li>
            <li class="has-text-info">{{item.factory.name}}</li>
          </ul>
        </div>  -->
        <div class="column is-full">
            <router-view/>
        </div>
    </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
}
</script>

<style scoped>
.menu-list-item{
  padding: 10px;
}
.menu-list-item:hover{
  background-color: #fff;
}
.list-item a{
  margin-left: px;
  display: block;
}
</style>
